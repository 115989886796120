import styled from "styled-components";

import arrowSvg from "shared/images/arrow.svg";
import { EmptyPlaceholder } from "shared/ui/EmptyPlaceholder";

type TableProps = {
  striped?: boolean;
  withArrows?: boolean;
};

type RowProps = {
  clickable?: boolean;
  isActive?: boolean;
};

type CellProps = {
  align?: "left" | "right" | "center";
  width?: string;
  minWidth?: string;
  flexGrow?: number;
  color?: string;
};

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  @media (max-width: 768px) {
    -webkit-overflow-scrolling: touch;
  }
`;

export const Table = styled.table<TableProps>`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  tbody tr:nth-child(odd) {
    background: ${(props) =>
      props.striped ? props.theme.gradients.tableRow : ""};
  }

  ${({ withArrows }) =>
    withArrows &&
    `
      thead tr {
        padding-right: 41px;
      }

      tbody tr::after {
        content: "";
        width: 6px;
        height: 10px;
        background: url(${arrowSvg}) no-repeat;
        background-position: center;
      }
  `}
`;

export const TableHead = styled.thead`
  border-bottom: 1px solid #565378;
`;

export const TableRow = styled.tr<RowProps>`
  height: 59px;
  padding: 0 35px;
  display: flex;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "auto")};
  &&& {
    ${({ isActive, theme }) =>
      isActive &&
      `
        background: ${theme.gradients.activeRace};
        &:hover {
          background: ${theme.gradients.activeRace};
        }
      `};
  }
`;

const EmptyTableRow = styled.tr`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TableHeadCell = styled.th<CellProps>`
  flex-grow: ${({ flexGrow }) => (flexGrow !== undefined ? flexGrow : 1)};
  flex-shrink: 1;
  flex-basis: 0;
  text-align: ${({ align = "center" }) => align};
  font-weight: 400;
  color: #7c7987;
  overflow-wrap: break-word;
  width: ${({ width }) => width || "150px"};
  min-width: ${({ minWidth }) => minWidth || "150px"};
`;

export const TableCell = styled.td<CellProps>`
  flex-grow: ${({ flexGrow }) => (flexGrow !== undefined ? flexGrow : 1)};
  flex-shrink: 1;
  flex-basis: 0;
  font-weight: 600;
  text-align: ${({ align = "center" }) => align};
  overflow-wrap: break-word;
  width: ${({ width }) => width || "150px"};
  min-width: ${({ minWidth }) => minWidth || "150px"};
  color: ${({ color }) => color};
`;

const EmptyTableCell = styled.td`
  flex-grow: 1;
`;

export const TableBody = styled.tbody`
  margin-top: 15px;
  display: block;
  height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;

  ${TableRow}:hover {
    background: #2d333a;
  }
`;

export const EmptyTablePlaceholder: React.FC = ({ children }) => (
  <EmptyTableRow>
    <EmptyTableCell>
      <EmptyPlaceholder>{children}</EmptyPlaceholder>
    </EmptyTableCell>
  </EmptyTableRow>
);