import { useCallback, useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { Championship } from "shared/types/championship";
import { Burger } from "shared/ui/icons/Burger";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import { CreateCupModal } from "./CreateCupModal";
import { formatDateToAustralian } from "./lib";
import {  $cups, createCup, CupGate, deleteCupFx, loadCupsFx } from "./model";


const PromotionOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

type CellProps = {
  width: number;
  color?: string;
};

const TableHeadCell = styled.th<CellProps>`
  width: ${({ width }) => width}%;
  text-align: ${({ align = "center" }) => align};
  font-weight: 400;
  color: #7c7987;
  overflow-wrap: break-word;
  border-right: 1px solid #565378;

   &:last-child {
    border-right: none;
  }
`;

const TableCell = styled.td<CellProps>`
  width: ${({ width }) => width}%;
  font-weight: 600;
  text-align: ${({ align = "center" }) => align};
  overflow-wrap: break-word;
  color: ${({ color }) => color};
`;

const Button = styled.button`
  padding: 8px 30px;  
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  background: ${({ theme }) => theme.gradients.button};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
  font-size: 16px;
  &:hover {
    opacity: 0.8;
  }
`;

export const Championships: React.FC = () => {
  useGate(CupGate);

  const cups = useStore($cups);
  const isLoading = useStore(loadCupsFx.pending);
  const isDeleting = useStore(deleteCupFx.pending);

  const [cupId, setCupId] = useState<string | null>(null);

  const deleteCup = (id: string) => deleteCupFx(id);

  const handleClosePopover = useCallback(() => setCupId(null), []);
  const handleTogglePopover = useCallback(
    (cup: Championship) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        cup.id && setCupId(cup.id);
      },
    []
  );
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Championships</Underlined>
          </Typography>
        }
        amountComponent={
          <Button onClick={() => createCup({
            cupName: "",
            mainRace: 1,
            date: "",
            track: "",
           
          })
          }>ADD NEW+</Button>
        }
      >
        <Table striped >
          <TableHead>
            <TableRow>
              <TableHeadCell width={5}><Burger /></TableHeadCell>
              <TableHeadCell width={30}>Name</TableHeadCell>
              <TableHeadCell width={30}>Track</TableHeadCell>
              <TableHeadCell width={20}>Date</TableHeadCell>
              <TableHeadCell width={10}>Main race</TableHeadCell>
              <TableHeadCell width={5}>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(isLoading || isDeleting) && <Loader />}
            {cups.length === 0 && (
              <EmptyTablePlaceholder>
                No championships available
              </EmptyTablePlaceholder>
            )}
            {cups.map((cup, index) => (
               <TableRow key={cup.id} clickable>
               <TableCell width={5}>{index + 1}</TableCell>
               <TableCell width={30}>{cup.cupName}</TableCell>
               <TableCell width={30}>{cup.track}</TableCell>
               <TableCell width={20}>{formatDateToAustralian(cup.date)}</TableCell>
               <TableCell width={10}>
                {cup.mainRace}
               </TableCell>
               <TableCell width={5}>
                 <Popover
                   isOpen={cupId === cup.id}
                   onClose={handleClosePopover}
                   content={
                     <>
                       <PopoverOption
                         onClick={() => {
                           handleClosePopover();
                           cup && cup.id && deleteCup(cup.id);
                         }
                         }
                       >
                         Delete championships
                       </PopoverOption>
                     </>
                   }
                 >
                   <PromotionOptionsButton
                      onClick={handleTogglePopover(cup)}
                   >
                     <UserOptionsIcon />
                   </PromotionOptionsButton>
                 </Popover>
               </TableCell>
             </TableRow>
            ))}
            
          </TableBody>
        </Table>
        <CreateCupModal />

      </PageLayout>
    </>
  );
};
