import axios from "axios";

import { history } from "router/history";

const baseURL = window.location.origin;
export const http = axios.create({ baseURL, withCredentials: true, headers: { Accept: "application/json", "Content-Type": "application/json" } });

export const isProduction = baseURL !== "https://jan.staging.biorithmico.com";

export const setToken = (token: string): void => {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

let isRefreshing = false;
const subscribers: ((token: string) => void)[] = [];

const onRefreshed = (token: string) => {
  subscribers.forEach((callback) => callback(token));
  subscribers.length = 0;
};

const subscribeTokenRefresh = (callback: (token: string) => void) => {
  subscribers.push(callback);
};

const clearTokens = () => {
  localStorage.removeItem("REFRESH_TOKEN");
  localStorage.removeItem("ACCESS_TOKEN");
};

const refreshAccessToken = async (): Promise<string> => {
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");
  if (!refreshToken) {
    clearTokens();
    history.push("/login");
    return "";
  }
  const response = await http.post("api/authentication/refresh-access-token", {
    token: refreshToken,
  }, { timeout: 20000 });
  const { accessToken, refreshToken: newRefreshToken } = response.data.data;
  if (!!accessToken && !!newRefreshToken) {
    localStorage.setItem("REFRESH_TOKEN", newRefreshToken);
    setToken(accessToken);
    localStorage.setItem("ACCESS_TOKEN", accessToken);
    return accessToken;
  }
  clearTokens();
  history.push("/login");
  return "";
};

const handle401Error = async (error: any) => {
  const originalRequest = error.config;

  if (
    error.response &&
    error.response.status === 401 &&
    error.response.data.message === "You're not authorized" &&
    !originalRequest._retry
  ) {
    if (originalRequest.url === "api/authentication/refresh-access-token") {
      clearTokens();
      history.push("/login");
      return Promise;
    }
    originalRequest._retry = true;
    if (isRefreshing) {
      return new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(http(originalRequest));
        });
      });
    }
    isRefreshing = true;
    const newAccessToken = await refreshAccessToken();
    if (newAccessToken) {
      isRefreshing = false;
      onRefreshed(newAccessToken);
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return http(originalRequest);
    }
    isRefreshing = false;
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

http.interceptors.response.use(
  (response) => {
    if (response.status >= 500) {
      console.log("Server glitched. We're resolving it now!");
    }
    return response;
  },
  (error) => handle401Error(error)
);