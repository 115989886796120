import { addMinutes, isBefore, parseISO } from "date-fns";
import styled from "styled-components";

import { Race as RaceType } from "shared/types/races";
import { GreenText } from "shared/ui/GreenText";
import { RightArrow } from "shared/ui/icons/RightArrow";
import { Typography } from "shared/ui/Typography";
import { YellowText } from "shared/ui/YellowText";

import { getRaceStatus } from "../lib";
import { Day } from "../types";

const Container = styled.button<{ isActive: boolean }>`
  padding: 0 36px;
  height: 120px;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: ${({ isActive, theme }) =>
    isActive ? theme.gradients.activeRace : "#2d333a"};
  }
  background: ${({ isActive, theme }) =>
    isActive ? theme.gradients.activeRace : "transparent"};
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div<{ isActive: boolean }>`
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid ${({ isActive, theme }) =>
    isActive ? theme.colors.background : theme.colors.active};
};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 19px;
  background: ${({ isActive }) => (isActive ? "#030A23" : "transparent")};
`;

const RaceTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
`;

const RaceSubtitle = styled(Typography)`
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 3px;
`;

const Status = styled(Typography)`
  color: #ffffff;
`;

type Props = {
  isActive: boolean;
  race: RaceType;
  track: string;
  type: Day;
  onClick: () => void;
};
const is20minAgo = (date: string): boolean => {
  const now = new Date();
  const parsedDate = parseISO(date);
  return isBefore(addMinutes(parsedDate, 20), now);
};

export const Race: React.FC<Props> = ({
  isActive,
  race,
  type,
  onClick,
}) => {
  const raceStatus = getRaceStatus(race);

  return (
    <Container isActive={isActive} onClick={onClick}>
      <Content>
        <Icon isActive={isActive}>R{race.number}</Icon>
        <TextWrap>
          <RaceTitle variant="bold20">Race {race.number}</RaceTitle>
          {type === "today" && (
            <>
              <RaceSubtitle variant="regular16">
                Status: <Status variant="semiBold16">{raceStatus}</Status>
              </RaceSubtitle>

              {raceStatus !== "Finished" ? (
                <RaceSubtitle variant="regular16">
                  Picks:{" "}
                  {race.picksAvailable ? (
                    is20minAgo(race.startTime) ? (
                      <YellowText
                      >
                        Unavailable
                      </YellowText>
                    ) : (
                      <GreenText>
                        Available
                      </GreenText>
                    )
                  ) : (
                    <YellowText >
                      Picks will be available soon
                    </YellowText>
                  )}
                </RaceSubtitle>
              ) : ""
              }
            </>
          )}
        </TextWrap>
      </Content>
      <RightArrow />
    </Container>
  );
};
