import styled from "styled-components";

import { Typography } from "shared/ui/Typography";

export const PredictionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const Prediction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WinsPrediction = styled(Prediction)`
  left: 35px;

  @media (max-width: 1024px) {
    left: 0px;
  }
`;

export const PlacesPrediction = styled(Prediction)`
  left: -35px;

  @media (max-width: 1024px) {
    left: 0px;
  }
`;

export const PredictionLabel = styled(Typography)`
  position: relative;
  margin-top: -20px;
  color: rgba(255, 255, 255, 0.4);
`;

export const BoldLabel = styled.span`
  font-weight: 600;
  color: rgb(255, 255, 255);
`;
