import { createEffect, forward, restore } from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { createPageable } from "shared/paging";
import { PaymentsStatistic } from "shared/types/payment";

import { customerInfoModel } from "features/customerInfo";

export const loadPaymentsStatisticFx = createEffect<void, PaymentsStatistic>(
  api.fetchPaymentsStatistic
);

export const $paymentsStatistic = restore(loadPaymentsStatisticFx, null);

export const PaymentsGate = createGate();

export const {
  $data,
  $isEmpty,
  loadFirst,
  loadNext,
  loadPage,
  loadPrev,
  update,
  changePageSize,
} = createPageable(api.fetchPayments);

forward({
  from: PaymentsGate.open,
  to: [loadFirst, loadPaymentsStatisticFx],
});

forward({
  from: PaymentsGate.close,
  to: customerInfoModel.hideCustomerInfo,
});
