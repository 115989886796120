import styled from "styled-components";

import { Typography } from "shared/ui/Typography";

const Head = styled.div`
  padding: 20px 0;
  padding-left: 36px;
  background: ${({ theme }) => theme.gradients.activeRace};

  @media (max-width: 768px) {
    padding-left: 20px;
  }

  @media (max-width: 480px) {
    padding-left: 10px;
  }
`;

const RegionName = styled(Typography)`
  font-weight: 600;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

type Props = {
  name: string;
};

export const Region: React.FC<Props> = ({ name, children }) => {
  return (
    <div>
      <Head>
        <RegionName variant="bold20">{name}</RegionName>
      </Head>
      {children}
    </div>
  );
};