import { Horse } from "shared/types/races";
import { SidePanel } from "shared/ui/SidePanel";

import { HorsesInfo } from "./HorsesInfo";

type Props = {
  horses: Horse[];
  onClose: () => void;
};

export const RaceInfo: React.FC<Props> = ({ horses, onClose }) => {

  return (
    <SidePanel withClose={false} onClose={onClose}>
      <HorsesInfo horses={horses} />
    </SidePanel>
  );
};
