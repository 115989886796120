import React, { useCallback, useEffect, useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { calculatePlan } from "shared/lib/calculatePlan";
import { getFullName } from "shared/lib/getFullName";
import { Paging } from "shared/paging";
import { Customer, Plan } from "shared/types/customer";
import { GreenText } from "shared/ui/GreenText";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import { Search } from "shared/ui/Search";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";
import { YellowText } from "shared/ui/YellowText";

import { CustomerInfo, customerInfoModel } from "features/customerInfo";
import { hideCustomerInfo } from "features/customerInfo/model";
import { customerSubscribeModel } from "features/customerSubscribe";
import { setActiveCreditsRecipient } from "features/giveCredits";
import { GiveCreditsModal } from "features/giveCredits/ui/GiveCreditsModal";

import { DeactivationCustomerModal } from "./DeactivationCustomerModal";
import { DeleteCustomerModal } from "./DeleteCustomerModal";
import {
  $customersTotal,
  $data,
  $isDeactivateCustomerModalVisible,
  $isDeleteCustomerModalVisible,
  $isEmpty,
  $isLoading,
  changePageSize,
  CustomersGate,
  loadNext,
  loadPage,
  loadPrev,
  markCustomerForDeactivating,
  markCustomerForDeletion,
  searchText,
  update,
} from "./model";

const Amount = styled(Typography)`
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const Email = styled(Typography)`
  text-decoration-line: underline;
  width: 70%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const CustomerOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 480px) {
    padding: 6px;
  }
`;

const SearchField = styled.div`
  margin-left: 40px;
  border: 1px solid white;
  width: 310px;
  height: 40px;
  border-radius: 50px;
  display: flex;

  @media (max-width: 768px) {
    flex: 1;
    height: 36px;
  }

  @media (max-width: 480px) {
    height: 32px;
    flex: 1;
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  }
`;

export const CustomersPage: React.FC = () => {
  useGate(CustomersGate);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>(
    0 as unknown as NodeJS.Timeout
  );
  useEffect(() => () => clearTimeout(timeoutId), []);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const handleClosePopover = useCallback(() => setCustomerId(null), []);
  const handleTogglePopover = useCallback(
    (customer: Customer) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setCustomerId((value) => (value ? null : customer.id));
      },
    []
  );
  const customerAction = useCallback(
    (action: (id: string) => void, id: string) => () => {
      action(id);
      setTimeoutId(setTimeout(update, 100));
      setCustomerId(null);
    },
    []
  );
  const updateUserBalance = () => {
    setTimeoutId(setTimeout(update, 100));
  };

  const { data, ...paging } = useStore($data);
  const isEmpty = useStore($isEmpty);
  const isLoading = useStore($isLoading);
  const isVisibleDeleteModal = useStore($isDeleteCustomerModalVisible);
  const isVisibleDeactivatingModal = useStore($isDeactivateCustomerModalVisible);
  const totalCustomers = useStore($customersTotal);
  const activeCustomerId = useStore(customerInfoModel.$customerId);
  const isCustomerInfoVisible = useStore(
    customerInfoModel.$isCustomerInfoVisible
  );
  const selectCustomer = (customerId: string) =>
    customerInfoModel.showCustomerInfo(customerId);
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Customers</Underlined>
          </Typography>
        }
        amountComponent={
          <Amount variant="bold20">
            Total amount of Customers: {totalCustomers ? totalCustomers : ""}
          </Amount>
        }
      >
        {isLoading && !isVisibleDeactivatingModal && !isVisibleDeleteModal && <Loader zIndex={-1} />}
            <SearchField>
              <Search value={paging.searchText} onSearch={(value) => searchText(value)} />
            </SearchField>
            <TableContainer>
              <Table striped>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Username</TableHeadCell>
                    <TableHeadCell>Email</TableHeadCell>
                    {!isCustomerInfoVisible && (
                      <>
                        <TableHeadCell>Subscription</TableHeadCell>
                        <TableHeadCell flexGrow={0} minWidth="70px" width="70px">Credits</TableHeadCell>
                        <TableHeadCell flexGrow={0} minWidth="70px" width="70px">Total spent</TableHeadCell>
                        <TableHeadCell flexGrow={0} minWidth="70px" width="70px" />
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isEmpty ? (
                    <EmptyTablePlaceholder>
                      You have no Customers yet
                    </EmptyTablePlaceholder>
                  ):(data.map((customer) => (
                    <TableRow
                      key={customer.id}
                      onClick={() => selectCustomer(customer.id)}
                      clickable
                      isActive={customer.id === activeCustomerId}
                    >
                      <TableCell>{getFullName(customer)}</TableCell>
                      <TableCell>
                        <Email variant="regular16">{customer.email}</Email>
                      </TableCell>
                      {!isCustomerInfoVisible && (
                        <>
                          <TableCell>
                            {calculatePlan(customer) === Plan.Free ? (
                              <YellowText>{calculatePlan(customer)}</YellowText>
                            ) : (
                              <GreenText>{calculatePlan(customer)}</GreenText>
                            )}
                          </TableCell>
                          <TableCell flexGrow={0} minWidth="70px" width="70px">
                            <YellowText>
                              {calculatePlan(customer) === Plan.Free
                                ? customer.ticketsBalance
                                : "N/A"}
                            </YellowText>
                          </TableCell>
                          <TableCell flexGrow={0} minWidth="70px" width="70px">${customer.paymentTotal.amount}</TableCell>
                          <TableCell flexGrow={0} minWidth="70px" width="70px" align="right">
                            <Popover
                              isOpen={customerId === customer.id}
                              onClose={handleClosePopover}
                              content={
                                <>
                                  {customer.isActive ? (
                                    <PopoverOption
                                      onClick={customerAction(
                                        customerSubscribeModel.deactivateCustomerFx,
                                        customer.id
                                      )}
                                    >
                                      Deactivate
                                    </PopoverOption>
                                  ) : (
                                    <PopoverOption
                                      onClick={customerAction(
                                        customerSubscribeModel.activateCustomerFx,
                                        customer.id
                                      )}
                                    >
                                      Activate
                                    </PopoverOption>
                                  )}
                                  <PopoverOption
                                    onClick={() =>
                                      setActiveCreditsRecipient(customer)
                                    }
                                  >
                                    Give credits
                                  </PopoverOption>
                                  <PopoverOption
                                    onClick={() => {
                                      markCustomerForDeletion({
                                        id: customer.id || "",
                                        firstName: customer.firstName || "",
                                        lastName: customer.lastName || "",
                                      });
                                      handleClosePopover();
                                    }
                                    }
                                  >
                                    Delete user
                                  </PopoverOption>
                                  <PopoverOption
                                    onClick={() => {
                                      markCustomerForDeactivating({
                                        id: customer.id || "",
                                        firstName: customer.firstName || "",
                                        lastName: customer.lastName || "",
                                      });
                                      handleClosePopover();
                                    }
                                    }
                                  >
                                    Deactivate user
                                  </PopoverOption>
                                </>
                              }
                            >
                              <CustomerOptionsButton
                                onClick={handleTogglePopover(customer)}
                              >
                                <UserOptionsIcon />
                              </CustomerOptionsButton>
                            </Popover>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
            <Paging
              {...paging}
              loadNext={loadNext}
              loadPrev={loadPrev}
              loadPage={loadPage}
              changePageSize={changePageSize}
            />
       


      </PageLayout>
      <DeleteCustomerModal />
      <DeactivationCustomerModal />
      <GiveCreditsModal onClose={updateUserBalance} />
      <Overlay isOpen={isCustomerInfoVisible} onClick={() => hideCustomerInfo()} />
      {isCustomerInfoVisible && <CustomerInfo />}
    </>
  );
};