import React from "react";
import { useStore } from "effector-react";
import styled from "styled-components";

import { calculatePlan } from "shared/lib/calculatePlan";
import { formatISODate } from "shared/lib/date";
import { DATE_WITH_TIME_FORMAT, SHORT_DATE_FORMAT } from "shared/lib/date";
import { getFullName } from "shared/lib/getFullName";
import pluralize from "shared/lib/pluralize";
import { Plan } from "shared/types/customer";
import { GreenText } from "shared/ui/GreenText";
import { Loader } from "shared/ui/Loader";
import { SidePanel } from "shared/ui/SidePanel";
import { Typography } from "shared/ui/Typography";
import { YellowText } from "shared/ui/YellowText";

import { getPaymentType } from "./lib";
import {
  $customerDetails,
  $customerPayments,
  $customerPaymentsLoaded,
  hideCustomerInfo,
  loadCustomerDetailsFx,
} from "./model";

const Container = styled.div`
  margin-top: 36px;
  padding-top: 36px;
  border-top: 1px solid #1f2340;

  @media (max-width: 768px) {
    margin-top: 30px;
    padding-top: 30px;
  }

  @media (max-width: 480px) {
    margin-top: 24px;
    padding-top: 24px;
    width: 100%;
  }
`;

const Title = styled(Typography)`
  display: block;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    margin-bottom: 12px;
  }
`;

export const CustomerInfo: React.FC = () => {
  const customerDetails = useStore($customerDetails);
  const customerPayments = useStore($customerPayments);
  const customerPaymentsLoaded = useStore($customerPaymentsLoaded);
  const isLoading = useStore(loadCustomerDetailsFx.pending);

  if (!customerDetails || !customerPaymentsLoaded) return null;

  return (
    <SidePanel
      title={customerDetails ? getFullName(customerDetails) : ""}
      onClose={hideCustomerInfo}
    >
      {isLoading ? (
        <Container> <Loader /></Container>
      ) : (
        <>
          <Container>
            <Title variant="semiBold18">Personal details</Title>
            <ul>
              <ListItem>
                <Typography variant="regular16">Email</Typography>
                <div style={{ marginLeft: 16, textAlign: "right" }}>
                  <Typography overflowWrap="anywhere" variant="semiBold16">
                    {customerDetails.email || "-"}
                  </Typography>
                </div>
              </ListItem>
              {
                customerDetails.phoneNumber ? (<ListItem>
                  <Typography variant="regular16">Phone</Typography>
                  <div style={{ marginLeft: 16, textAlign: "right" }}>
                    <Typography variant="semiBold16">
                      {customerDetails.phoneNumber || "-"}
                    </Typography>
                  </div>
                </ListItem>):(<> </>)
              }
              {
                customerDetails.firstAddress ? (<ListItem>
                  <Typography variant="regular16">Address 1</Typography>
                  <Typography variant="semiBold16">
                    {customerDetails.firstAddress || "-"}
                  </Typography>
                </ListItem>):(<> </>)
              }
              {
                customerDetails.firstAddress ? (<ListItem>
                  <Typography variant="regular16">Address 1</Typography>
                  <Typography variant="semiBold16">
                    {customerDetails.firstAddress || "-"}
                  </Typography>
                </ListItem>):(<> </>)
              }
              {
                customerDetails.secondAddress ? (<ListItem>
                  <Typography variant="regular16">Address 2</Typography>
                  <Typography variant="semiBold16">
                    {customerDetails.secondAddress || "-"}
                  </Typography>
                </ListItem>):(<> </>)
              }
              {
                customerDetails.suburb ? (<ListItem>
                  <Typography variant="regular16">Suburb</Typography>
                  <Typography variant="semiBold16">
                    {customerDetails.suburb || "-"}
                  </Typography>
                </ListItem>):(<> </>)
              }
              {
                customerDetails.state ? (<ListItem>
                  <Typography variant="regular16">State</Typography>
                  <Typography variant="semiBold16">
                    {customerDetails.state || "-"}
                  </Typography>
                </ListItem>):(<> </>)
              }
              {
                customerDetails.postcode ? (<ListItem>
                  <Typography variant="regular16">Postcode</Typography>
                  <Typography variant="semiBold16">
                    {customerDetails.postcode || "-"}
                  </Typography>
                </ListItem>):(<> </>)
              }
              <ListItem>
                <Typography variant="regular16">Day of Registration</Typography>
                <Typography variant="semiBold16">
                  {customerDetails?.dayOfRegistration &&
                    formatISODate(
                      customerDetails.dayOfRegistration,
                      SHORT_DATE_FORMAT
                    )}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="regular16">Subscription</Typography>
                <Typography variant="semiBold16">
                  {calculatePlan(customerDetails) === Plan.Free ? (
                    <YellowText>{calculatePlan(customerDetails)}</YellowText>
                  ) : (
                    <GreenText>{calculatePlan(customerDetails)}</GreenText>
                  )}
                </Typography>
              </ListItem>
            </ul>
          </Container>
          <Container>
            <Title variant="semiBold18">Payment history</Title>
            {customerPayments.length ? (
              <ul>
                {customerPayments.map((payment, index) => (
                  <ListItem key={index}>
                    <Typography variant="regular16">
                      {getPaymentType(payment.type)}
                    </Typography>
                    {payment.packSize ? (
                      <Typography variant="regular16">
                        {payment.packSize} {pluralize(payment.packSize, "credit")}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Typography variant="semiBold16">
                      {formatISODate(payment.date, DATE_WITH_TIME_FORMAT)}
                    </Typography>
                  </ListItem>
                ))}
              </ul>
            ) : (
              <Typography variant="regular16">
                No prior payment history for this customer
              </Typography>
            )}
          </Container>
        </>
      )}
    </SidePanel>
  );
};