import styled from "styled-components";

const Page = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 60px;
  flex: 1;
  position: relative;
`;

const PageHead = styled.div`
  margin-bottom: 48px;
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  titleComponent: React.ReactNode;
  searchComponent?: React.ReactNode;
  amountComponent?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({
  titleComponent,
  searchComponent,
  amountComponent,
  children,
}) => (
  <Page>
    <PageHead>
      {titleComponent}
      {searchComponent}
      {amountComponent}
    </PageHead>
    {children}
  </Page>
);
