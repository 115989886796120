import styled from "styled-components";

import { Horse } from "shared/types/races";
import { Typography } from "shared/ui/Typography";

const Title = styled(Typography)`
  margin-bottom: 50px;
  display: block;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
`;

const TableContainer = styled.div`
  overflow-y: auto;
  margin-bottom: 50px;
  max-height: calc(100vh - 200px);
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    margin-bottom: 30px;
  }    
`;
const Table = styled.table`
  width: 100%;
`;

const TableRow = styled.tr<{ isScratched?: boolean }>`
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #1f2340;
  padding: 20px 0;
  position: relative;
  align-items: center;
  ${({ isScratched, theme }) =>
    isScratched &&
    `&:after {
      content: "Scratched";
      position: absolute;
      left: -42px;
      top: 0;
      right: -42px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.error};
      font-weight: 600;
      font-size: 16px;
      background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(16,24,29,0.9) 42px, rgba(16,24,29,0.9) calc(100% - 42px), rgba(16,24,29,0) 100%);
    }`};

  @media (max-width: 768px) {
    padding: 15px 0;
  }
`;

const TableHead = styled.thead`
  & tr {
    padding-top: 0;
  }
`;

const TableHeadCell = styled.th`
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  overflow-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const TableCell = styled.td`
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

type Props = {
  horses: Horse[];
};

export const HorsesInfo: React.FC<Props> = ({ horses }) => {
  return (
    <>
      <Title variant="bold20">Form</Title>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Runner</TableHeadCell>
              <TableHeadCell>Jockey/Trainer</TableHeadCell>
            </TableRow>
          </TableHead>
          <tbody>
            {horses?.map(
              ({ name, listNumber, jockey, scratched, trainerName }) => (
                <TableRow
                  key={`${name}${listNumber}${jockey}`}
                  isScratched={scratched}
                >
                  <TableCell>{`${listNumber}. ${name}`}</TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      alignItems: "flex-end",
                    }}
                  >
                    <div>J: {jockey}</div>
                    <div>T: {trainerName}</div>
                  </TableCell>
                </TableRow>
              )
            )}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};