import { useStore } from "effector-react";

import { Typography } from "shared/ui/Typography";

import { ModalButton } from "features/user/createUser/ui/CreateUserModal";
import { Modal } from "pages/Promotions/Modal";

import { CreateCupForm } from "./CreateCupForm";
import { $isCreateCupModalVisible, $isSuccessMessageVisible, cancelCupCreation } from "./model";

export const CreateCupModal: React.FC = () => {
  const isOpen = useStore($isCreateCupModalVisible);
  const isSuccess = useStore($isSuccessMessageVisible);

  const handleCloseModal = () => cancelCupCreation();

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      {isSuccess ? (
        <>
          <Typography variant="bold20">
           {isSuccess}
          </Typography>
          <ModalButton onClick={handleCloseModal}>Continue</ModalButton>
        </>
      ) : (
        <CreateCupForm />
      )}
    </Modal>
  );
};
