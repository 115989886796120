import styled from "styled-components";

interface Props {
  variant: "regular16" | "semiBold16" | "semiBold18" | "bold20";
  overflowWrap?: "anywhere" | "break-word" | "normal";
}

export const Typography = styled.span<Props>`
  ${(props) => {
    const variant = props.theme.typography[props.variant];

    return `
      font-family: ${variant.fontFamily};
      font-size: ${variant.fontSize};
      font-style: ${variant.fontStyle};
      font-weight: ${variant.fontWeight};
      line-height: ${variant.lineHeight};
      letter-spacing: ${variant.letterSpacing || ""};
      font-feature-settings: ${variant.fontFeatureSettings || ""};
      overflow-wrap: ${props.overflowWrap || "normal"};
    `;
  }}
`;
