import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useStore } from "effector-react";
import { transparentize } from "polished";
import styled from "styled-components";

import { ReactComponent as LogoSvg } from "shared/images/janLogo.svg";
import { Advertising } from "shared/ui/icons/Advertising";
import { CupIcon } from "shared/ui/icons/CupIcon";
import { CustomersIcon } from "shared/ui/icons/Customers";
import { MenuIcon } from "shared/ui/icons/MenuIcon";
import { PowerIcon } from "shared/ui/icons/Power";
import { RacesIcon } from "shared/ui/icons/Races";
import { UserProfileIcon } from "shared/ui/icons/UserProfile";
import { WalletIcon } from "shared/ui/icons/Wallet";
import { Typography } from "shared/ui/Typography";

import { $account, logoutFx } from "features/auth/model";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
`;

const BurgerButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 1024px) {
    display: block;
  }
`;

const SidePanel = styled.aside<{ isOpen: boolean }>`
  padding-top: 60px;
  padding-bottom: 60px;
  width: 238px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  transition: transform 0.3s ease-in-out;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    transform: translateX(${({ isOpen }) => (isOpen ? "0" : "-100%")});
    z-index: 2000;
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  }
`;

const Logo = styled.div`
  margin: 0 auto;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 650px;
`;

const MenuLink = styled(NavLink)`
  padding-left: 42px;
  padding-right: 42px;
  height: 60px;
  display: flex;
  align-items: center;

  color: ${({ theme }) => transparentize(0.2, theme.colors.text)};
  text-decoration: none;

  & ${Typography} {
    margin-left: 20px;
  }

  &.active {
    background: ${({ theme }) => theme.gradients.button};

    & ${Typography} {
      color: ${({ theme }) => theme.colors.text};
      font-weight: 600;
    }

    & path,
    rect {
      fill: ${({ theme }) => theme.colors.text};
    }
  }

  & path,
  rect {
    fill: ${({ theme }) => theme.colors.input};
  }
`;

const LogOutButton = styled.button`
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 42px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  color: ${({ theme }) => transparentize(0.2, theme.colors.text)};

  cursor: pointer;
  background-color: transparent;
  border: none;

  & span {
    margin-left: 20px;
  }
`;

const ViewerContainer = styled.div`
  padding-left: 42px;
`;

const ViewerTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.4;
`;

const ViewerEmail = styled(Typography)`
  display: block;
  color: ${({ theme }) => theme.colors.active};
`;

export const Layout: React.FC = ({ children }) => {
  const handleLogOut = () => logoutFx();
  const viewer = useStore($account);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (!viewer) return null;

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <Wrapper>
      <BurgerButton onClick={toggleMenu}>
      <MenuIcon />
      </BurgerButton>
      <Overlay isOpen={isMenuOpen} onClick={toggleMenu} />
      <SidePanel isOpen={isMenuOpen}>
        <Logo>
          <LogoSvg width={100} height={100} />
        </Logo>
        <Menu>
          <MenuLink to="/races/today" onClick={toggleMenu}>
            <RacesIcon />
            <Typography variant="regular16">Races</Typography>
          </MenuLink>
          <MenuLink to="/customers" onClick={toggleMenu}>
            <CustomersIcon />
            <Typography variant="regular16">Customers</Typography>
          </MenuLink>
          <MenuLink to="/payments" onClick={toggleMenu}>
            <WalletIcon />
            <Typography variant="regular16">Payments</Typography>
          </MenuLink>
          <MenuLink to="/users" onClick={toggleMenu}>
            <UserProfileIcon />
            <Typography variant="regular16">Users</Typography>
          </MenuLink>
          <MenuLink to="/promotions" onClick={toggleMenu}>
            <Advertising />
            <Typography variant="regular16">Promotions</Typography>
          </MenuLink>
          <MenuLink to="/championships" onClick={toggleMenu}>
            <CupIcon />
            <Typography variant="regular16">Championships</Typography>
          </MenuLink>

          <LogOutButton onClick={handleLogOut}>
            <PowerIcon />
            <Typography variant="regular16">Log Out</Typography>
          </LogOutButton>
        </Menu>

        <ViewerContainer>
          <ViewerTitle variant="regular16">Logged in:</ViewerTitle>
          <ViewerEmail variant="semiBold18">
            {viewer.firstName} {viewer.lastName}
          </ViewerEmail>
        </ViewerContainer>
      </SidePanel>
      {children}
    </Wrapper>
  );
};
