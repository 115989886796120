import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { formatISODate } from "shared/lib/date";
import { SHORT_DATE_FORMAT } from "shared/lib/date";
import { formatMoney } from "shared/lib/formatMoney";
import { getFullName } from "shared/lib/getFullName";
import pluralize from "shared/lib/pluralize";
import { Paging } from "shared/paging";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import { CustomerInfo, customerInfoModel } from "features/customerInfo";
import { hideCustomerInfo } from "features/customerInfo/model";

import {
  $data,
  $isEmpty,
  changePageSize,
  loadNext,
  loadPage,
  loadPaymentsStatisticFx,
  loadPrev,
  PaymentsGate,
} from "./model";


const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  }
`;

export const PaymentsPage: React.FC = () => {
  useGate(PaymentsGate);

  const { data, ...paging } = useStore($data);

  const activeCustomerId = useStore(customerInfoModel.$customerId);
  const isEmpty = useStore($isEmpty);
  const isCustomerInfoVisible = useStore(
    customerInfoModel.$isCustomerInfoVisible
  );

  const selectCustomer = (customerId: string) =>
    customerInfoModel.showCustomerInfo(customerId);
  const loading = useStore(loadPaymentsStatisticFx.pending);

  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Payments</Underlined>
          </Typography>
        }
      >
        {loading && <Loader />}
        <TableContainer>
          <Table striped withArrows={!isEmpty}>
            <TableHead>
              <TableRow>
                <TableHeadCell minWidth="150px">Username</TableHeadCell>
                <TableHeadCell minWidth="150px">Last Payments</TableHeadCell>
                <TableHeadCell minWidth="150px">Payment amount</TableHeadCell>
                <TableHeadCell minWidth="150px">Payment type</TableHeadCell>
                <TableHeadCell minWidth="150px">Total Spent</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty ? (
                <EmptyTablePlaceholder>
                  You have no Payments yet
                </EmptyTablePlaceholder>
              ):(data.map((customer) => (
                <TableRow
                  key={customer.id}
                  onClick={() => selectCustomer(customer.id)}
                  clickable
                  isActive={customer.id === activeCustomerId}
                >
                  <TableCell minWidth="150px">{getFullName(customer)}</TableCell>
                  <TableCell minWidth="150px">
                    <Typography variant="regular16">
                      {customer?.lastPaymentAt &&
                        formatISODate(customer.lastPaymentAt, SHORT_DATE_FORMAT)}
                    </Typography>
                  </TableCell>
                  <TableCell minWidth="150px">
                    {customer?.lastPayment && formatMoney(customer.lastPayment)}
                  </TableCell>
                  <TableCell minWidth="150px">
                    {customer.lastPaymentType === "SUBSCRIPTION" ? "Subscription" : `${customer.lastPaymentPackSize} ${pluralize(customer.lastPaymentPackSize ? customer.lastPaymentPackSize : 0, "credit")}`}
                  </TableCell>
                  <TableCell minWidth="150px">
                    {customer?.paymentTotal && formatMoney(customer.paymentTotal)}
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          {...paging}
          loadNext={loadNext}
          loadPrev={loadPrev}
          loadPage={loadPage}
          changePageSize={changePageSize}
        />
      </PageLayout>
      <Overlay isOpen={isCustomerInfoVisible} onClick={() => hideCustomerInfo()} />
      {isCustomerInfoVisible ? <CustomerInfo /> : null}
    </>
  );
};
