import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useStore } from "effector-react";
import moment from "moment";
import styled from "styled-components";

import { useServerFormErrors } from "shared/hooks";
import { Promotion } from "shared/types/promotions";
import { Button } from "shared/ui/Button";
import { Calendar } from "shared/ui/icons/Calendar";
import { Select } from "shared/ui/Select";
import { Typography } from "shared/ui/Typography";

import { FormField } from "./FormField";
import { InputModal } from "./InputModal";
import { formatToDateTimeLocal, packsLabels } from "./lib";
import { $creationError, $errors, $promotion, createPromotionFx, editPromotionFx } from "./model";

import "moment-timezone";

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormTitle = styled(Typography)`
  margin-bottom: 30px;
  font-size: 18px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 14px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  margin-left: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.inactive};
  font-size: 12px;

  @media (max-width: 768px) {
    margin-left: 10px;
    font-size: 11px;
  }

  @media (max-width: 480px) {
    margin-left: 8px;
    font-size: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const RowItem = styled.div`
  display: flex;
  width: 25%;
  box-sizing: border-box;
  padding: 5px;
  justify-content: flex-start;

  @media (max-width: 768px) {
    // width: 45%;
  }

  @media (max-width: 480px) {
    // width: 100%;
  }
`;

const ErrorWrap = styled.div`
  margin: 6px 0;
  height: 17px;
`;

const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  display: block;
  text-align: right;
  margin-right: 5px;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const PackButton = styled.button<{ selected: boolean }>`
  position: relative;
  color: #ffffff;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 40px;

  &:hover {
    background-color: #3a3a4d;
  }

  &::before {
    content: "";
    position: absolute;
    left: 10px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid ${({ selected }) => (selected ? "#36e36b" : "#a9a9b2")};
  }

  &::after {
    content: "${({ selected }) => (selected ? "✔" : "")}";
    position: absolute;
    left: 16px;
    top: 14px;
    font-size: 12px;
    color: #36e36b;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 10px;
    padding-left: 35px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 8px;
    padding-left: 35px;
  }
`;

const DateTimeInput = styled(InputModal)`
  position: relative;
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    display: none;
  }
`;

const CalendarContainer = styled.span`
  position: absolute;
  left: 90%;
  top: 50%;
  transform: translateY(-50%);
`;

const defaultStartDate = moment().tz("Australia/Sydney").format("YYYY-MM-DDTHH:mm");
const defaultEndDate = moment(defaultStartDate).add(7, "days").format("YYYY-MM-DDTHH:mm");

export const CreatePromotionForm = () => {
  const serverErrors = useStore($errors);
  const promotion = useStore($promotion);
  const creating = useStore(createPromotionFx.pending);
  const editing = useStore(editPromotionFx.pending);
  const creatingError = useStore($creationError);
  const [creditsPacks, setPacks] = useState<string[]>([]);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [typeError, setTypeError] = useState<string | null>(null);
  const [packsError, setPacksError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(formatToDateTimeLocal(defaultStartDate));
  const [endDate, setEndDate] = useState(formatToDateTimeLocal(defaultEndDate));
  const [notificationError, setNotificationError] = useState<string | null>(null);
  const [isActive, setIsActive] = useState(true);

  const defaultValues = promotion ? promotion : {
    bonus: 0,
    bonusType: "NUMBER",
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    isActive: true,
    packs: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Promotion>({ defaultValues });

  useServerFormErrors(serverErrors, setError);

  const togglePack = (value: string) => {
    setPacks((prevPacks) =>
      prevPacks.includes(value)
        ? prevPacks.filter((item) => item !== value)
        : [...prevPacks, value]
    );
  };

  const onSubmit = (data: Promotion) => {
    data.bonus < 1 && setAmountError("Value must be greater than 0");
    !data.bonusType && setTypeError("Please select bonus type");
    creditsPacks.length === 0 && setPacksError("Please select at least one pack");
    moment(data.startDate).isAfter(data.endDate) && setDateError("Start date should be before end date");
    (data.title && !data.message || !data.title && data.message) && setNotificationError("Both title and message must be present or both must be absent.");
    if (data.bonus < 1 || !data.bonusType || creditsPacks.length === 0 || moment(data.startDate).isAfter(data.endDate) || (data.title && !data.message || !data.title && data.message)) {
      return;
    }

    const formattedData = {
      ...data,
      bonus: Number(data.bonus),
      bonusType: data.bonusType ?? "",
      packs: creditsPacks,
      startDate: moment.tz(startDate, "Australia/Sydney").utc().toISOString(),
      endDate: moment.tz(endDate, "Australia/Sydney").utc().toISOString(),
      isActive: isActive,
    };
    data.id && data.bonusType ? editPromotionFx(formattedData) :
      createPromotionFx(formattedData);
  };

  useEffect(() => {
    creditsPacks.length === 0 && setPacksError("Please select at least one pack");
    creditsPacks.length > 0 && setPacksError(null);
  }, [creditsPacks]);

  useEffect(() => {
    if (promotion) {
      setPacks(promotion.packs);
      if (promotion.startDate) {
        setStartDate(formatToDateTimeLocal(promotion.startDate));
      }
      if (promotion.endDate) {
        setEndDate(formatToDateTimeLocal(promotion.endDate));
      }
    }
  }, [promotion]);

  const startDateInputRef = useRef<HTMLInputElement | null>(null);
  const endDateInputRef = useRef<HTMLInputElement | null>(null);

  const handleIsActiveChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value === "true";
    setIsActive(value);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle variant="bold20">{promotion?.id ? "Edit promotion" : "Create new promotion"}</FormTitle>
      <Row>
        <FormField error={errors.bonus?.message || (amountError ? amountError : undefined)}>
          <Label>Bonus amount:</Label>
          <InputModal
            type="number"
            placeholder="Write amount"
            error={!!errors.bonus}
            {...register("bonus")}
            onChange={(e) => +e.target.value > 0 ? setAmountError(null) : setAmountError("Value must be greater than 0")}
          />
        </FormField>
        <FormField error={typeError ? typeError : undefined}>
          <Label>Bonus type:</Label>
          <Select
            {...register("bonusType")}
            onChange={() => setTypeError(null)}
          >
            <option value="" disabled selected hidden>Select type</option>
            <option value="NUMBER">Number</option>
            <option value="PERCENTAGE">Percentage</option>
          </Select>
        </FormField>
      </Row>
      <div>
        <Label>Select packs:</Label>
        <Container>
          {packsLabels.map((pack) => (
            <RowItem key={pack.value}>
              <PackButton
                type="button"
                selected={creditsPacks.includes(pack.value)}
                onClick={() => togglePack(pack.value)}
              >
                {pack.label}
              </PackButton>
            </RowItem>
          ))}
        </Container>
        <ErrorWrap>
          <Error>{packsError ? packsError : undefined}
          </Error>
        </ErrorWrap>
      </div>
      <Row>
        <FormField error={errors.startDate?.message}>
          <Label>Start Date:</Label>
          <DateTimeInput
            type="datetime-local"
            {...register("startDate", { required: "Start date is required" })}
            ref={startDateInputRef}
            value={startDate}
            onChange={(e) => setStartDate(formatToDateTimeLocal(e.target.value))}
          ></DateTimeInput>
          <CalendarContainer onClick={() => { startDateInputRef.current && startDateInputRef.current.showPicker(); }}><Calendar /></CalendarContainer>
        </FormField>
        <FormField error={errors.endDate?.message || (dateError ? dateError : undefined)}>
          <Label>End Date:</Label>
          <DateTimeInput
            type="datetime-local"
            {...register("endDate", { required: "End date is required" })}
            ref={endDateInputRef}
            value={endDate}
            onChange={(e) => setEndDate(formatToDateTimeLocal(e.target.value))}
          />
          <CalendarContainer onClick={() => { endDateInputRef.current && endDateInputRef.current.showPicker(); }}><Calendar /></CalendarContainer>
        </FormField>
      </Row>
      <FormField error={errors.isActive?.message}>
        <Label>Active:</Label>
        <Select
          {...register("isActive")}
          onChange={handleIsActiveChange}
        >
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </Select>
      </FormField>
      <FormField>
        <Label>Title (optional) :</Label>
        <InputModal
          type="text"
          placeholder="Write title for push notification"
          {...register("title")}
          onChange={() => setNotificationError(null)}
        />
      </FormField>
      <FormField>
        <Label>Message (optional) :</Label>
        <InputModal
          type="text"
          placeholder="Write message for push notification"
          {...register("message")}
          onChange={() => setNotificationError(null)}
        />
      </FormField>
      <Button type="submit" disabled={creating || editing}>{
        promotion?.id ? (creating || editing) ? "Editing..." : "Edit Promotion" : (creating || editing) ? "Creating..." : "Create Promotion"}
      </Button>
      <ErrorWrap>
        <Error>{notificationError ? notificationError : creatingError ? creatingError : undefined}
        </Error>
      </ErrorWrap>
    </Form>
  );
};