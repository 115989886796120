import styled from "styled-components";
import ReactModal, {
  BaseModalBackground,
  ModalProvider,
} from "styled-react-modal";

import { CrossIcon } from "shared/ui/icons/Cross";

export const ModalComponent = ReactModal.styled`
  width: 700px;
  max-height: 80vh;
  box-sizing: border-box;
  background: #1f1937;
  box-shadow: 0px 4px 10px rgba(3, 10, 35, 0.25);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    padding: 12px;
  }

  @media (max-width: 480px) {
    width: 95%;
    padding: 10px;
  }
`;

export const Background = styled(BaseModalBackground)`
  background: rgba(3, 10, 35, 0.85);
`;

export const Close = styled.button`
  width: 32px;
  height: 32px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }

  @media (max-width: 480px) {
    width: 24px;
    height: 24px;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  padding: 0px 40px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0px 30px 30px;
  }

  @media (max-width: 480px) {
    padding: 0px 20px 20px;
  }
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const Modal: React.FC<Props> = ({ isOpen, onClose, children }) => (
  <ModalProvider backgroundComponent={Background}>
    <ModalComponent
      isOpen={isOpen}
      onEscapeKeydown={onClose}
    >
      <Close onClick={onClose}>
        <CrossIcon />
      </Close>
      <ModalContent>{children}</ModalContent>
    </ModalComponent>
  </ModalProvider>
);