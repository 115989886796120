import { Redirect, Route } from "react-router-dom";
import { useStore } from "effector-react";
import styled from "styled-components";

import { InfoIcon } from "shared/ui/icons/InfoIcon";
import { PageLayout } from "shared/ui/PageLayout";

import { PageLink } from "./ui/PageLink";
import { RaceInfo } from "./ui/RaceInfo";
import { Races } from "./ui/Races";
import { Statistics } from "./ui/Statistics";
import { $activeRace, $currentRace, $isSideBarShow, $statistic, closeSideBar, openSideBar, setActiveRace } from "./model";

const BurgerButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 1024px) {
    display: block;
  }
`;

const SidePanel = styled.aside<{ isOpen: boolean }>`
max-height: 100vh;
overflow-y: auto;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  transition: transform 0.3s ease-in-out;

  & > *:not(:last-child) {
    margin-bottom: 60px;
  }

  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    transform: translateX(${({ isOpen }) => (isOpen ? "0" : "100%")});
    z-index: 999;
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  }
`;

export const RacesPage: React.FC = () => {
  const activeRace = useStore($activeRace);
  const statistic = useStore($statistic);
  const currentRace = useStore($currentRace);
  const horses = currentRace?.horses || [];
  const isMenuOpen = useStore($isSideBarShow);

  return (
    <>
      <PageLayout
        titleComponent={
          <div>
            <PageLink to="/races/today">Today Races</PageLink>
            <PageLink to="/races/yesterday">Yesterday Races</PageLink>
          </div>
        }
      >
        <Route path="/races/today">
          <Races day="today" />
        </Route>
        <Route path="/races/yesterday">
          <Races day="yesterday" />
        </Route>
        <Redirect from="/races/*?" to="/races/today" />
      </PageLayout>
      <BurgerButton onClick={() => {
        setActiveRace(null);
         !isMenuOpen ? openSideBar() : closeSideBar(); }}>
        <InfoIcon />
      </BurgerButton>
      <Overlay isOpen={isMenuOpen} onClick={() => closeSideBar()} />
      <SidePanel isOpen={isMenuOpen}>
        {activeRace ? (
          <RaceInfo
            horses={horses}
            onClose={() => {
              setActiveRace(null);
              closeSideBar();
              }}
          />
        ) : (
          <Statistics statistic={statistic} />
        )}
      </SidePanel>
    </>
  );
};
