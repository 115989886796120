import { useCallback, useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { Championship } from "shared/types/championship";
import { Burger } from "shared/ui/icons/Burger";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import { Button } from "pages/Promotions";

import { CreateCupModal } from "./CreateCupModal";
import { formatDateToAustralian } from "./lib";
import {  $cups, createCup, CupGate, deleteCupFx, loadCupsFx } from "./model";


const PromotionOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

export const Championships: React.FC = () => {
  useGate(CupGate);

  const cups = useStore($cups);
  const isLoading = useStore(loadCupsFx.pending);
  const isDeleting = useStore(deleteCupFx.pending);

  const [cupId, setCupId] = useState<string | null>(null);

  const deleteCup = (id: string) => deleteCupFx(id);

  const handleClosePopover = useCallback(() => setCupId(null), []);
  const handleTogglePopover = useCallback(
    (cup: Championship) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        cup.id && setCupId(cup.id);
      },
    []
  );
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Championships</Underlined>
          </Typography>
        }
        amountComponent={
          <Button onClick={() => createCup({
            cupName: "",
            mainRace: 1,
            date: "",
            track: "",
           
          })
          }>ADD NEW+</Button>
        }
      >
        <TableContainer>
        <Table striped >
          <TableHead>
            <TableRow>
              <TableHeadCell flexGrow={0} minWidth="30px" width="30px"><Burger /></TableHeadCell>
              <TableHeadCell >Name</TableHeadCell>
              <TableHeadCell>Track</TableHeadCell>
              <TableHeadCell >Date</TableHeadCell>
              <TableHeadCell flexGrow={0} minWidth="50px" width="50px">Main {"\n"}
                 race</TableHeadCell>
              <TableHeadCell flexGrow={0} minWidth="80px" width="80px">Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(isLoading || isDeleting) && <Loader />}
            {cups.length === 0 ? (
              <EmptyTablePlaceholder>
                No championships available
              </EmptyTablePlaceholder>
            ):(cups.map((cup, index) => (
              <TableRow key={cup.id} clickable>
              <TableCell flexGrow={0} minWidth="30px" width="30px">{index + 1}</TableCell>
              <TableCell>{cup.cupName}</TableCell>
              <TableCell >{cup.track}</TableCell>
              <TableCell >{formatDateToAustralian(cup.date)}</TableCell>
              <TableCell flexGrow={0} minWidth="50px" width="50px">
               {cup.mainRace}
              </TableCell>
              <TableCell flexGrow={0} minWidth="80px" width="80px">
                <Popover
                  isOpen={cupId === cup.id}
                  onClose={handleClosePopover}
                  content={
                    <>
                      <PopoverOption
                        onClick={() => {
                          handleClosePopover();
                          cup && cup.id && deleteCup(cup.id);
                        }
                        }
                      >
                        Delete championships
                      </PopoverOption>
                    </>
                  }
                >
                  <PromotionOptionsButton
                     onClick={handleTogglePopover(cup)}
                  >
                    <UserOptionsIcon />
                  </PromotionOptionsButton>
                </Popover>
              </TableCell>
            </TableRow>
           )))}          
          </TableBody>
        </Table>
        </TableContainer>
        <CreateCupModal />
      </PageLayout>
    </>
  );
};
