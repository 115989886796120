import { useCallback, useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { Promotion } from "shared/types/promotions";
import { Burger } from "shared/ui/icons/Burger";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import { CreatePromotionModal } from "./CreatePromotionModal";
import { formatDateToAustralian, getPacksText } from "./lib";
import {
  $promotions,
  createPromotion,
  deletePromotionFx,
  editPromotionFx,
  PromotionsGate,
} from "./model";

const PromotionOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

export const Button = styled.button`
  padding: 8px 30px;  
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  background: ${({ theme }) => theme.gradients.button};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
  font-size: 16px;
  &:hover {
    opacity: 0.8;
  }
`;

export const Promotions: React.FC = () => {
  useGate(PromotionsGate);

  const promotions = useStore($promotions);
  const isLoading = useStore(deletePromotionFx.pending || editPromotionFx.pending);

  const [promotionId, setPromotionId] = useState<string | null>(null);

  const deletePromotion = (id: string) => deletePromotionFx(id);

  const handleClosePopover = useCallback(() => setPromotionId(null), []);
  const handleTogglePopover = useCallback(
    (promotion: Promotion) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        promotion.id && setPromotionId(promotion.id);
      },
    []
  );
  return (
    <>
      <PageLayout
        titleComponent={
          <Typography variant="bold20">
            <Underlined>Advertising company</Underlined>
          </Typography>
        }
        amountComponent={
          <Button onClick={() => createPromotion(null)
          }>ADD NEW+</Button>
        }
      >
        {isLoading && <Loader />}
        <TableContainer>
          <Table striped >
            <TableHead>
              <TableRow>
                <TableHeadCell flexGrow={0} minWidth="30px" width="30px"><Burger /></TableHeadCell>
                <TableHeadCell flexGrow={0} minWidth="80px" width="80px">Bonus</TableHeadCell>
                <TableHeadCell>Bonus Type</TableHeadCell>
                <TableHeadCell>Packs</TableHeadCell>
                <TableHeadCell>Start Date</TableHeadCell>
                <TableHeadCell>End Date</TableHeadCell>
                <TableHeadCell>Status</TableHeadCell>
                <TableHeadCell flexGrow={0} minWidth="80px" width="80px">Actions</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotions.length === 0 ? (
                <EmptyTablePlaceholder>
                  No promotions available
                </EmptyTablePlaceholder>
              ):(promotions.map((promotion: Promotion, index: number) => (
                <TableRow key={promotion.id} clickable>
                  <TableCell flexGrow={0} minWidth="30px" width="30px">{index + 1}</TableCell>
                  <TableCell flexGrow={0} minWidth="80px" width="80px">{promotion.bonus}</TableCell>
                  <TableCell>{promotion.bonusType}</TableCell>
                  <TableCell>{getPacksText(promotion.packs)}</TableCell>
                  <TableCell>
                    {formatDateToAustralian(promotion.startDate)}
                  </TableCell>
                  <TableCell>
                    {formatDateToAustralian(promotion.endDate)}
                  </TableCell>
                  <TableCell color={promotion.isActive ? "#05fe86" : "#FDD440"}>{promotion.isActive ? "Active" : "Inactive"}</TableCell>
                  <TableCell flexGrow={0} minWidth="80px" width="80px">
                    <Popover
                      isOpen={promotionId === promotion.id}
                      onClose={handleClosePopover}
                      content={
                        <>
                          <PopoverOption
                            onClick={() => {
                              handleClosePopover();
                              promotion && promotion.id && createPromotion(promotion);
                            }
                            }
                          >
                            Edit promotion
                          </PopoverOption>
                          <PopoverOption
                            onClick={() => {
                              handleClosePopover();
                              promotion && promotion.id && deletePromotion(promotion.id);
                            }
                            }
                          >
                            Delete promotion
                          </PopoverOption>
                        </>
                      }
                    >
                      <PromotionOptionsButton
                        onClick={handleTogglePopover(promotion)}
                      >
                        <UserOptionsIcon />
                      </PromotionOptionsButton>
                    </Popover>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
        <CreatePromotionModal />
      </PageLayout>
    </>
  );
};
