import styled from "styled-components";

import { Statistic } from "shared/types/races";
import { RadialChart, SemiCircleChart } from "shared/ui/charts";
import { SidePanel } from "shared/ui/SidePanel";
import { Typography } from "shared/ui/Typography";

import { Line } from "./Line";
import {
  BoldLabel,
  PlacesPrediction,
  PredictionContainer,
  PredictionLabel,
  WinsPrediction,
} from "./prediction";

const Title = styled(Typography)`
  display: block;
  text-transform: capitalize;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    margin-bottom: 12px;
  }
`;

const AdaptivePredictionContainer = styled(PredictionContainer)`
  display: flex;
  flex-direction: row;
  // justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

type Props = {
  statistic: Statistic | null;
};

export const Statistics: React.FC<Props> = ({ statistic }) => (
  <SidePanel background>
    <Title variant="bold20">Statistics</Title>
    <SemiCircleChart
      value={statistic?.overall || 50}
      label="Average win & place rate"
    />
    <Line />
    <Typography variant="bold20">AI prediction</Typography>
    <AdaptivePredictionContainer>
      <WinsPrediction>
        <RadialChart
          value={statistic?.winsPercentage || 0}
          label="Wins"
          color="orange"
          size="small"
        />
        <PredictionLabel variant="regular16">
          Races: <BoldLabel>{statistic?.races || 0}</BoldLabel>
        </PredictionLabel>
      </WinsPrediction>
      <PlacesPrediction>
        <RadialChart
          value={statistic?.placesPercentage || 0}
          label="Places"
          color="yellow"
          size="small"
        />
        <PredictionLabel variant="regular16">
          Picks predicted:{" "}
          <BoldLabel>{statistic?.picksPredicted || 0}</BoldLabel>
        </PredictionLabel>
      </PlacesPrediction>
    </AdaptivePredictionContainer>
  </SidePanel>
);