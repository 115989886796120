import { useRef, useState } from "react";
import AsyncSelect, { StylesConfig } from "react-select";
import Select from "react-select";
import { isBefore, parseISO } from "date-fns";
import { useStore } from "effector-react";
import moment from "moment";
import { transparentize } from "polished";
import styled from "styled-components";
import { customTheme } from "theme";

import { Button } from "shared/ui/Button";
import { Calendar } from "shared/ui/icons/Calendar";
import { Typography } from "shared/ui/Typography";

import { FormField } from "pages/Promotions/FormField";
import { InputModal } from "pages/Promotions/InputModal";

import { formatToDateTimeLocal } from "./lib";
import { $errors, $tracks, createCupFx, getTracksListFx, resetError, setSearchTracks } from "./model";

import "moment-timezone";

const numberOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const Form = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const FormTitle = styled(Typography)`
  margin-bottom: 30px;
  font-size: 18px;
`;

const Label = styled.label`
 display: block;
 margin-bottom: 5px;
 margin-left: 12px;
 text-align: left;
 color: ${({ theme }) => theme.colors.inactive};
 font-size: 12px;
`;

const DateTimeInput = styled(InputModal)`
  position: relative;
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    display: none;
  }
`;

const CalendarContainer = styled.span`
  position: absolute;
  left: 90%;
  top: 50%;
  transform: translateY(-50%);

`;

const defaultDate = moment().tz("Australia/Sydney").format("YYYY-MM-DD");

export const CreateCupForm = () => {

  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles, backgroundColor: customTheme.colors.background, borderWidth: 0, padding: "8px 12px", borderRadius: "8px", color: customTheme.colors.input, ":hover": { ...styles[":active"], backgroundColor: customTheme.colors.darkBackground, }
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: customTheme.colors.background,
        color: customTheme.colors.input,
        cursor: "default",
        ":hover": {
          ...styles[":active"],
          backgroundColor: customTheme.colors.darkBackground
        },
      };
    },
    input: (styles) => ({ ...styles, color: customTheme.colors.input }),
    placeholder: (styles) => ({ ...styles, color: transparentize(0.7, customTheme.colors.input) }),
    singleValue: (styles) => ({ ...styles, color: customTheme.colors.input }),
    menu: (styles) => ({ ...styles, backgroundColor: customTheme.colors.background, borderRadius: "8px" }),
  };

  const creating = useStore(createCupFx.pending);

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string | null>(null);

  const [track, setTrack] = useState<string>("");
  const [trackError, setTrackError] = useState<string | null>(null);

  const [race, setRace] = useState<string>("");

  const [date, setDate] = useState(defaultDate);
  const [dateError, setDateError] = useState("");
  const serverError = useStore($errors);
  
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (name.length === 0) {
      return setNameError("Name is required");
    }
    if (track.length === 0) {
      return setTrackError("Track is required");
    }
    if (isBefore(parseISO(date), new Date())) {
      return setDateError("Date can't be in the past");
    }
    const convertToISO = (dateString: string): string => {
      const date = new Date(dateString);
      date.setUTCHours(0, 0, 0, 0);
      return date.toISOString();
    };
    const isoDateStr = convertToISO(date);
    const dto = {
      cupName: name,
      date: isoDateStr,
      track: track,
      mainRace: +race
    };
    createCupFx(dto);
  };

  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const tracks = useStore($tracks);
  const isLoadingTracks = useStore(getTracksListFx.pending);
  return (
    <Form>
      <FormTitle variant="bold20">Add new championship</FormTitle>
      <FormField error={nameError ? nameError : serverError.length!==0 ? serverError[0].message : undefined}>
        <Label>Championship name:</Label>
        <InputModal
          type="text"
          placeholder="Name"
          error={!!nameError}
          value={name}
          onChange={(e) => {
            setNameError(null);
            setName(e.target.value);
            resetError();
          }}
        />
      </FormField>
      <FormField error={trackError ? trackError : undefined}>
        <Label>Track name:</Label>
        <AsyncSelect isLoading={isLoadingTracks} onInputChange={(e: string) => setSearchTracks(e)} onChange={(e: { value: string, label: string }) => { setTrack(e.value); setTrackError(null); }} placeholder={"Start typing the name"} options={tracks} styles={colourStyles} />
      </FormField>
      <FormField error={dateError ? dateError : undefined}>
        <Label>Date:</Label>
        <DateTimeInput
          type="date"
          ref={dateInputRef}
          value={date}
          onChange={(e) => {
            setDate(formatToDateTimeLocal(e.target.value));
            isBefore(parseISO(e.target.value), new Date()) ? setDateError("Date can't be in the past"): setDateError("");
          }}
        ></DateTimeInput>
        <CalendarContainer onClick={() => { dateInputRef.current && dateInputRef.current.showPicker(); }}><Calendar /></CalendarContainer>
      </FormField>
      <FormField>
        <Label>Race number:</Label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={numberOptions[0]}
          name="color"
          options={numberOptions}
          onChange={(e: { value: string }) => setRace(e.value)}
          styles={colourStyles}
        />
      </FormField>
      <Button onClick={(e) => onSubmit(e)} disabled={creating}>{creating ? "Creating..." : "Create championship"}
      </Button>
    </Form>
  );
};




