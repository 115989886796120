import styled from "styled-components";

import { Typography } from "shared/ui/Typography";

const Container = styled.div`
  margin-top: 36px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  @media (max-width: 480px) {
    margin-top: 12px;
  }
`;

const Name = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  padding: 0 36px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0 24px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 0 12px;
  }
`;

type Props = {
  name: string;
};

export const Track: React.FC<Props> = ({ name, children }) => {
  return (
    <Container>
      <Name variant="bold20">{name}</Name>
      {children}
    </Container>
  );
};