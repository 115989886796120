import styled from "styled-components";

export const Line = styled.div`
  border-bottom: 1px solid #1f2340;
   margin-top: 0px;
    margin-bottom: 48px;

  @media (max-width: 768px) {
     margin-top: 0px;
    margin-bottom: 24px;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 12px;
  }
`;