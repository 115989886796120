import { useState } from "react";
import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { Paging } from "shared/paging";
import { Manager } from "shared/types/manager";
import { Colored } from "shared/ui/Colored";
import { AssignAsAdminIcon } from "shared/ui/icons/AssignAsAdmin";
import { DeleteUserIcon } from "shared/ui/icons/DeleteUser";
import { UserOptionsIcon } from "shared/ui/icons/UserOptions";
import { Loader } from "shared/ui/Loader";
import { PageLayout } from "shared/ui/PageLayout";
import { Popover, PopoverOption } from "shared/ui/popover";
import {
  EmptyTablePlaceholder,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from "shared/ui/table";
import { Typography } from "shared/ui/Typography";
import { Underlined } from "shared/ui/Underlined";

import {
  AssignUserModal,
  assignUserModel,
  CreateUserButton,
  CreateUserModal,
  DeleteUserModal,
  deleteUserModel,
  RemoveUserFromAdminsModal,
  removeUserFromAdminsModel,
} from "features/user";
import { Email } from "pages/Customers";

import { isAdmin } from "./lib";
import {
  $data,
  $isEmpty,
  $isLoading,
  $usersTotal,
  changePageSize,
  loadNext,
  loadPrev,
  UsersGate,
} from "./model";

const ButtonTableHeadCell = styled(TableHeadCell)`
  display: flex;
  justify-content: flex-end;
`;

const UserOptionsButton = styled.button`
  padding: 10px;
  vertical-align: middle;
  display: inline-flex;
`;

const Amount = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
`;

export const UsersPage: React.FC = () => {
  useGate(UsersGate);
  const { data, ...paging } = useStore($data);
  const isLoading = useStore($isLoading);
  const isEmpty = useStore($isEmpty);
  const totalUsers = useStore($usersTotal);

  const [userId, setUserId] = useState<string | null>(null);

  const handleClosePopover = () => setUserId(null);

  const handleTogglePopover = (user: Manager) => () =>
    setUserId((value) => (value ? null : user.id));

  const handleDeleteUser = (user: Manager) => () =>
    deleteUserModel.markUserForDeletion(user);

  const handleAssignAdmin = (user: Manager) => () => {
    assignUserModel.markUserForAssignation(user);
  };

  const handleRemoveUser = (user: Manager) => () => {
    removeUserFromAdminsModel.markUserForRemoving(user);
  };

  return (
    <PageLayout
      titleComponent={
        <Typography variant="bold20">
          <Underlined>Users</Underlined>
        </Typography>
      }
      amountComponent={
        <Amount variant="bold20">Total amount of Users: {totalUsers ? totalUsers : ""}</Amount>
      }
    >
      {isLoading && <Loader />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell align="left">First Name</TableHeadCell>
              <TableHeadCell>Last Name</TableHeadCell>
              <TableHeadCell minWidth="200px">Email Address</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <ButtonTableHeadCell>
                <CreateUserButton />
              </ButtonTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty ? (
              <EmptyTablePlaceholder>You have no Users yet</EmptyTablePlaceholder>
            ) : (data.map((user) => (
              <TableRow key={user.id}>
                <TableCell align="left">{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell minWidth="200px">
                  <Email variant="semiBold18">{user.email}</Email>
                </TableCell>
                <TableCell>
                  <Colored color={isAdmin(user) ? "green" : "yellow"}>
                    {isAdmin(user) ? "admin" : "user"}
                  </Colored>
                </TableCell>
                <TableCell align="right">
                  <Popover
                    isOpen={userId === user.id}
                    onClose={handleClosePopover}
                    content={
                      <>
                        <PopoverOption onClick={handleDeleteUser(user)}>
                          <DeleteUserIcon />
                          Delete User
                        </PopoverOption>
                        <PopoverOption
                          onClick={
                            isAdmin(user)
                              ? handleRemoveUser(user)
                              : handleAssignAdmin(user)
                          }
                        >
                          <AssignAsAdminIcon />
                          {isAdmin(user)
                            ? "Remove from Admins"
                            : "Assign as Admin"}
                        </PopoverOption>
                      </>
                    }
                  >
                    <UserOptionsButton onClick={handleTogglePopover(user)}>
                      <UserOptionsIcon />
                    </UserOptionsButton>
                  </Popover>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
      {!paging.hasMore && paging.page === 1 ? (<></>) : (<Paging
        {...paging}
        loadNext={loadNext}
        loadPrev={loadPrev}
        changePageSize={changePageSize}
      />)}
      <DeleteUserModal />
      <CreateUserModal />
      <AssignUserModal />
      <RemoveUserFromAdminsModal />
    </PageLayout>
  );
};
