import styled from "styled-components";

import { Typography } from "./Typography";

const Text = styled(Typography)`
  background: ${({ theme }) => theme.gradients.yellow};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const YellowText: React.FC = ({ children }) => (
  <Text variant="semiBold16">{children}</Text>
);