import { createEffect, createEvent, createStore, restore, sample } from "effector";
import { createGate } from "effector-react";

import { api } from "shared/api";
import { Championship } from "shared/types/championship";


export const loadCupsFx = createEffect<void, Championship[]>(api.getAllCups);

export const createCupFx = createEffect<
  Championship,
  any,
  any
>();
createCupFx.use(api.createCup);

export const deleteCupFx = createEffect(api.deleteCup);

export const CupGate = createGate();

export const $cups = restore(loadCupsFx, []);

export const createCup = createEvent<Championship>();
export const cancelCupCreation = createEvent();
export const $cup = createStore<Championship | null>(null);

sample({
  clock: createCup,
  target: $cup,
});

export const $isCreateCupModalVisible = createStore(false);
export const $isSuccessMessageVisible = createStore("");

export const $errors = createStore<ServerFormError[]>([]);
export const resetError = createEvent();

$cup
  .on(createCup, (_, data) => data)
  .reset(cancelCupCreation);

$isSuccessMessageVisible
  .on(createCupFx.done, () => "Championship has successfully been added")
  .reset(cancelCupCreation);

$errors
  .on(createCupFx.fail, (_, { error }) => error.response?.data.errors || [])
  .reset([createCupFx.done, cancelCupCreation, resetError]);

$isCreateCupModalVisible.on(createCup, () => true).reset(cancelCupCreation);

sample({
  clock: [CupGate.open, createCupFx.done, deleteCupFx.done],
  target: loadCupsFx,
});

export const $creationError = createStore<string | null>(null).
  on(
    createCupFx.failData,
    (_, error) => error.response?.data?.message
  )
  .on(
    [createCupFx.done, cancelCupCreation],
    () => null
  );


export const $tracks = createStore<tracksOption[] | null>(null);

export type tracksOption = {
  value: string;
  label: string;
}

export const getTracksListFx = createEffect(api.getTracksList);

sample({
  clock: CupGate.open,
  fn: () => { return { page: 1 }; },
  target: getTracksListFx,
});
export const $searchTracks = createStore<string>("");
export const setSearchTracks = createEvent<string>();
$searchTracks.on(setSearchTracks, (_, value) => value);

sample({
  clock: $searchTracks,
  fn: (text) => {
    return { page: 1, searchText: text };
  },
  target: getTracksListFx,
});

sample({
  clock: getTracksListFx.done,
  fn: ({ result }) => {
    const obj = result.data.reduce((acc: tracksOption[], item: any) => {
      acc.push({ value: item, label: item });
      return acc;
    }, []);
    return obj as tracksOption[];
  },
  target: $tracks,
});